import { AppState } from './app/types'
import { AttributesState } from './attributes/types'
import { CoverageLevelsState } from './coverageLevels/types'
import { InsuranceTypesState } from './insuranceTypes/types'
import { QuotesState } from './quotes/types'
import { VehicleAttributesState } from './vehicleAttributes/types'

export interface GenericAction {
  type: string,
  payload?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface GenericState {
  data?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  cached: boolean,
  loading: boolean,
  error?: string | object,
}

export const genericInitialState = {
  data: undefined,
  cached: false,
  loading: false,
  error: undefined,
}

export interface RootState {
  app: AppState,
  attributes: AttributesState,
  coverageLevels: CoverageLevelsState,
  insuranceTypes: InsuranceTypesState,
  quotes: QuotesState,
  vehicleAttributes: VehicleAttributesState,
}

export type CacheResponse = Promise<boolean | void>
